<template>
  <div>
    <el-form ref="searchForm" :model="searchForm" inline>
      <el-form-item>
        <el-button type="primary" @click="rowCreate">新增</el-button>
      </el-form-item>
      <el-form-item>
        <el-upload 
          action="/upload"
          :show-file-list="false"
          :on-change="importExcel"
          :auto-upload="false">
          <el-button slot="trigger" type="primary">导入</el-button>
        </el-upload>
      </el-form-item>
      
      <el-form-item>
        <el-input v-model="searchForm.keyword_name_code" placeholder="模型" clearable/>
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchForm.type" placeholder="类型" clearable />
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchForm.keyword_check_table" placeholder="表名" clearable/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="refresh">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 300px)"
      :default-sort="tableSort"
      @sort-change="sortChange"
    >
      <el-table-column prop="code" label="模型编号" width="180" sortable="custom" />
      <el-table-column prop="name" label="模型名称" min-width="180" show-overflow-tooltip />
      <el-table-column prop="type" label="类型" width="140" />
      <el-table-column prop="check_table" label="表名" width="120" />
      <el-table-column prop="check_table_cn" label="表名中文" width="120" />
      <el-table-column prop="remark" label="规则说明" min-width="180" show-overflow-tooltip />
      
      <el-table-column label="操作" width="140" fixed="right">
        <template slot-scope="{row}">
          <el-button @click="$router.push(`${$route.path}/${row.id}`)" type="text" size="small">查看</el-button>
          <el-button @click="rowUpdate(row)" type="text" size="small">编辑</el-button>
          <el-button @click="rowRemove(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <edit-form ref="editForm" />
  </div>
</template>
<script>
import _ from 'lodash';
import * as XLSX from 'xlsx';

import EditForm from './EditForm'

export default {
  components: { EditForm },
  props: {
    node: Object
  },
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      tableSort: { prop: 'code', order: 'ascending' },

      searchForm: {},
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
    tableSort() {
      this.refresh()
    },
    node() {
      this.refresh()
    }
  },

  created() {
    this.refresh()
  }, 
  methods: {
    async refresh() {
      this.loading = true;
      const res = await this.$api.execute('data_qa.list', _.assign({}, this.searchForm, _.pick(this, ['current', 'pageSize', 'tableSort'])));
      this.tableData = res.list;
      this.total = res.count;
      this.loading = false;
    },

    sortChange({ prop, order }) {
      this.tableSort = order ? { prop, order } : null;
    },

    rowCreate() {
      this.$refs.editForm.show({ }, { title: '新增质量模型', success: () => {
        this.refresh();
      } });
    },
    rowUpdate(obj) {
      this.$refs.editForm.show(obj, { title: '编辑质量模型', success: () => {
        this.refresh();
      } });
    },
    async rowRemove(obj) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        await this.$api.execute('data_qa.remove', obj.id);
        this.refresh();
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(_.includes(['close', 'cancel'], e)) { console.log(e) }
        else {
          this.$message.error(e.message)
        }
      }
    },

    async importExcel(e) {

      const data = await (new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => resolve(e.target.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(e.raw)
      }));

      console.log(data)

      const workbook = XLSX.read(data, { type: 'array' })


      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const results = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

      const records = _(results.slice(3)).map(o => ({
        code: o[0],
        name: o[1],
        type: o[2],
        interface_name: o[3],
        check_table: o[4],
        check_table_cn: o[5],
        check_column: o[6],
        check_column_cn: o[7],
        check_sql: o[8],
        error_detail_sql: o[9],
        remark: o[10],
        total_sql: o[11],
        strong_check: o[12],
      })).filter('code').value();

      await this.$api.executeArray(..._.map(records, o => (['data_qa.replace', o])));

      console.log('importExcel', records, results)
    },
  }
}
</script>