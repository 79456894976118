<template>
  <codemirror ref="codemirror" :value="value" @input="e => $emit('input', e)" :options="options" />
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/sql/sql.js'
export default {
  components: { codemirror },
  props: {
    value: String,
  },
  data() {
    return {
      options: {
        tabSize: 4,
        mode: 'text/x-sql',
        lineNumbers: true,
        line: true,
      }
    }
  },
  methods: {
    refresh() {
      this.$refs.codemirror.refresh()
    }
  }
}
</script>