<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" top="20px" width="800px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">

      <el-tabs type="border-card" :value="tabValue" @tab-click="tabClick">
        <el-tab-pane label="模型信息" name="info">
          <div style="padding: 15px;">
            <el-form-item label="模型名称">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="模型编号">
              <el-input v-model="form.code"></el-input>
            </el-form-item>
            
            <el-row>
              <el-col :span="12">
                <el-form-item label="类型">
                  <el-input v-model="form.type"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item label="接口名">
                  <el-input v-model="form.interface_name"></el-input>
                </el-form-item>
              </el-col> -->
            </el-row>

            
            <el-row>
              <el-col :span="12">
                <el-form-item label="表名">
                  <el-input v-model="form.check_table"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="中文名">
                  <el-input v-model="form.check_table_cn"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- <el-row>
              <el-col :span="12">
                <el-form-item label="字段">
                  <el-input v-model="form.check_column"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="中文名">
                  <el-input v-model="form.check_column_cn"></el-input>
                </el-form-item>
              </el-col>
            </el-row> -->
            
            <el-form-item label="规则说明">
              <el-input v-model="form.remark" type="textarea" resize="none" :rows="3" />
            </el-form-item>

            <el-form-item label="数据源">
              <el-select v-model="form.db_source_id">
                <el-option v-for="o in db_source" :key="o.id" :label="o.name" :value="o.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-tab-pane>
        <el-tab-pane label="检查SQL" name="check_sql">
          <sql-editor ref="check_sql" v-model="form.check_sql" />
        </el-tab-pane>
        <el-tab-pane label="差错明细SQL" name="error_detail_sql">
          <sql-editor ref="error_detail_sql" v-model="form.error_detail_sql" />
        </el-tab-pane>
        <el-tab-pane label="总数SQL" name="total_sql">
          <sql-editor ref="total_sql" v-model="form.total_sql" />
        </el-tab-pane>
      </el-tabs>


      

    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
import SqlEditor from '@/components/SqlEditor';
export default {
  components: { SqlEditor },
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

      cronPopover: false,

      tabValue: 'info',

      db_source: [],
    }
  },

  created() {
    
  },

  methods: {
    async init() {
      this.db_source = (await this.$api.execute('db_source.list')).list;
    },
    async show(initForm, options = {}) {
      await this.init();
      
      this.form = _.assign({ }, initForm, { check_sql: initForm.check_sql || '', error_detail_sql: initForm.error_detail_sql || '', total_sql: initForm.total_sql || '' });

      this.tabValue = 'info';
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {

      const table_id = _.last(this.form.tableIds);
      try {
        if(this.form.id) {
          await this.$api.execute('data_qa.update', this.form.id, _.assign({}, _.omit(this.form, ['id']), { table_id }));
        } else {
          await this.$api.execute('data_qa.create', _.assign({}, this.form, { table_id }));
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        console.log({ e })
      }
    },

    tabClick(e) {
      if(_.includes(['check_sql', 'error_detail_sql', 'total_sql'], e.name)) {
        this.$refs[e.name].$refs.codemirror.refresh()
      }
      this.tabValue = e.name;
    }
  }
}
</script>

<style scoped>
/deep/ .el-tabs__content {
  padding: 0 !important;
}
</style>
